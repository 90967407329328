import React from 'react';
import { CustomColors } from '../../theme';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <h4 style={styles.heading}>Not Found</h4>
      <Button
        style={styles.btn}
        variant="contained"
        color="primary"
        onClick={() => navigate('/')}
      >
        Go Back
      </Button>
    </div>
  );
};

export default NotFound;

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  heading: {
    fontSize: 20,
    color: CustomColors.black,
  },
  btn: {
    width: 140,
  },
};
