import React from 'react';
import { Container } from '@mui/material';
import Donation from '../../components/Donation';
// import Footer from '../../components/Footer';

const Main = () => {
  return (
    <Container>
      <Donation />
      {/* <Footer /> */}
    </Container>
  );
};

export default Main;
