import { CustomColors } from '../../theme';

export const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heading: { textAlign: 'center', color: CustomColors.black },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  btn: {
    width: '100%',
  },
  icon: { height: 200, width: 200 },
  radioContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px',
    borderRadius: 10,
    marginTop: 5,
    cursor: 'pointer',
  },
  radioLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: 10,
  },
  radioLabelTextContainer: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '200px',
  },
  radioLabel: {
    marginLeft: 10,
  },
  radioIcon: { height: 40, width: 50 },
  helperText: {
    width: 300,
    color: CustomColors.black,
    fontSize: 12,
    marginLeft: 70,
    marginRight: 20,
    paddingBottom: 5,
  },
};
