import Header from '../components/Header';
import Navbar from '../components/Navbar';

interface Props {
  children: React.ReactNode;
}

const AppLayout = ({ children }: Props) => {
  return (
    <>
      <Navbar />
      <Header />
      {children}
    </>
  );
};

export default AppLayout;
