import axios from 'axios';

export const createPaymentAgreement = async (
  amount: string,
  checkoutMethod: string,
  {
    payIdPhone,
    payIdEmail,
    account,
    bsb,
  }: {
    payIdPhone?: string;
    payIdEmail?: string;
    account?: string;
    bsb?: string;
  }
) => {
  const res = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/monoova/create-payment-agreement`,
    {
      amount,
      checkoutMethod,
      payIdPhone,
      payIdEmail,
      account,
      bsb,
    }
  );
  return res.data;
};
