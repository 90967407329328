import { Routes, Route } from 'react-router-dom';
import NotFound from '../pages/Errors/NotFound';
import Main from '../pages/Main';

function RouteList() {
  return (
    <>
      <Routes>
        <Route index element={<Main />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default RouteList;
