import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import AppLayout from './layout';
import RouteList from './router/RouteList';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AppLayout>
          <RouteList />
        </AppLayout>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
