import { createTheme } from '@mui/material/styles';
import { CSSProperties } from 'react';

export const CustomColors = {
  primary: '#321964',
  secondary: '#4d65ff',
  white: '#fff',
  black: '#000',
  charcoalBlack: '#333333',
  lilac: '#d5d0df',
  brown: '#421414',
  sky100: '#EEF6F6',
  grey900: '#16191D',
  grey: '#6E7D91',
  lightBorder: '#D1CECE',
  error: '#ff1744',
  disabled: '#E8EAED;',
  success: '#4caf50',
};

export const theme = createTheme({
  palette: {
    primary: {
      main: CustomColors.primary,
      contrastText: CustomColors.white,
    },
    secondary: {
      main: CustomColors.secondary,
      contrastText: CustomColors.white,
    },
    default: {
      main: CustomColors.sky100,
      contrastText: CustomColors.grey900,
    },
    background: {
      default: CustomColors.sky100,
      paper: CustomColors.white,
    },
    backgroundSecondary: {
      default: CustomColors.secondary,
    },
    backgroundGrey: {
      default: CustomColors.grey,
    },
    text: {
      primary: CustomColors.charcoalBlack,
      secondary: CustomColors.charcoalBlack,
      secondaryDarker: CustomColors.grey,
    },
    border: {
      main: CustomColors.lightBorder,
      light: CustomColors.lightBorder,
    },
    info: {
      main: CustomColors.white,
      contrastText: CustomColors.black,
    },
    success: {
      main: CustomColors.success,
      contrastText: CustomColors.white,
    },
    error: {
      main: CustomColors.error,
    },
    disabled: {
      main: CustomColors.grey,
    },
  },
});

declare module '@mui/material/styles' {
  export interface Theme {}

  interface Palette {
    default: Palette['primary'];
    disabled: Palette['primary'];
    border: Palette['primary'];
    backgroundSecondary: Palette['background'];
    backgroundGrey: Palette['background'];
  }
  interface PaletteOptions {
    default: PaletteOptions['primary'];
    disabled: PaletteOptions['primary'];
    border: PaletteOptions['primary'];
    backgroundSecondary: PaletteOptions['background'];
    backgroundGrey: PaletteOptions['background'];
  }

  interface TypeText {
    secondaryDarker: TypeText['secondary'];
  }

  export interface TypographyVariants {
    h7: CSSProperties;
    body: CSSProperties;
    bodyXSmall: CSSProperties;
    bodySmall: CSSProperties;
    bodyLarge: CSSProperties;
    bodyXLarge: CSSProperties;
  }

  // allow configuration using `createTheme`
  export interface TypographyVariantsOptions {
    h7?: CSSProperties;
    body?: CSSProperties;
    bodyXSmall?: CSSProperties;
    bodySmall?: CSSProperties;
    bodyLarge?: CSSProperties;
    bodyXLarge?: CSSProperties;
  }
}
