import React from 'react';
import { Container } from '@mui/material';
import { CustomColors } from '../../theme';

const Header = () => {
  return (
    <Container>
      <div style={styles.main}>
        <h1>PayTo donation website</h1>
      </div>
    </Container>
  );
};

export default Header;

const styles = {
  main: {
    display: 'flex',
    height: 200,
    justifyContent: 'center',
    alignItems: 'center',
    color: CustomColors.black,
  },
};
