export const FORM_STATES = {
  FORM: 'FORM',
  CHECKOUT: 'CHECKOUT',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const AMOUNT_OPTIONS = [10, 20, 30, 40, 100];

export const CHECKOUT_METHODS = {
  PAYID_PHONE: 'PayID (Phone)',
  PAYID_EMAIL: 'PayID (Email)',
  ACCOUNT: 'Account',
};

export const PAYMENT_METHODS = {
  PAYTO: 'PayTo',
  PAYID: 'PayID',
};

export const MONOOVA_SAMPLE_TEST_ACCOUNTS = {
  BSB: '802950',
  ACCOUNT_NUMBER: '22090100',
  PAYID_PHONE: '+61-422020901',
  PAYID_EMAIL: 'sample@monoova.com',
};
