import * as React from 'react';
import Box from '@mui/material/Box';
import Logo from '../../assets/images/logo.svg';

export default function Navbar() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        textAlign: 'center',
      }}
    >
      <img src={Logo} style={styles.logo} alt="Monoova Logo" />
    </Box>
  );
}

const styles = {
  logo: {
    height: 80,
  },
};
