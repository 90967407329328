import { Box } from '@mui/material';
import { TabPanelProps } from './donation.type';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { styles } from './styles';

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: '100%' }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export const SuccessDialog = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <CheckCircleIcon style={styles.icon} color="success" />
      <p>Check your Banking App to approve PayTo Agreement.</p>
      <p>
        Once approved, we will then automatically debit your approved donation
        amount.
      </p>
    </div>
  );
};

export const ErrorDialog = () => {
  return <ErrorIcon style={styles.icon} color="error" />;
};
